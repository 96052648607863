import { createFocusTrap } from 'focus-trap';

// HEADER V.2 CHANGES ADDED DEC-2020 BM

// Sub menu trigger
const subMenuTriggers = document.getElementsByClassName("sub-menu-trigger");

var showSubMenu = function () {
    let closestMenu = this.nextElementSibling;
    closestMenu.classList.toggle("visible");
    this.classList.toggle("visible");

    if (this.classList.contains('visible')) {
        this.setAttribute('aria-expanded', 'true');
    } else {
        this.setAttribute('aria-expanded', 'false');
    }
};

// Show submenu when clicked
for (var i = 0; i < subMenuTriggers.length; i++) {
    subMenuTriggers[i].addEventListener("click", showSubMenu);
}

// Remove visible class on desktop when resizing from mobile
window.addEventListener("resize", function () {
    if (window.innerWidth > 1072) {
        // Remove Classes from all sub menus, sub menu triggers and menu items
        const activeSubMenus = document.querySelectorAll('.sub-menu.visible, .sub-menu-trigger.visible');
        for (var i = 0; i < activeSubMenus.length; i++) {
            activeSubMenus[i].classList.remove("visible");
        }
        hideMenu();
    }
});

// GetNext Sibling function 
const getNextSibling = function (elem, selector) {
    // Get the next sibling element
    var sibling = elem.nextElementSibling;
    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (sibling.matches(selector)) return sibling;
        sibling = sibling.nextElementSibling
    }
};

// Show sub-menu on desktop if focused
const hasChildren = document.querySelectorAll('.has-children > a');
for (var i = 0; i < hasChildren.length; i++) {
    hasChildren[i].addEventListener('focusin', function () {
        this.setAttribute('aria-expanded', "true");
        if (window.innerWidth > 1072) {
            let hasChildrenMenu = getNextSibling(this, '.sub-menu');
            let lastChild = hasChildrenMenu.lastElementChild.firstElementChild;
            hasChildrenMenu.classList.add('visible');

            // Remove class wehen reverse tabbing off active item
            this.addEventListener('keydown', function (e) {
                if (e.keyCode == 9 && e.shiftKey == true) {
                    hasChildrenMenu.classList.remove('visible');
                    this.setAttribute('aria-expanded', "false");
                }
            });

            // Remove class when tabbing off the last item
            lastChild.addEventListener('keydown', function (e) {
                if (e.keyCode == 9 && e.shiftKey == false) {
                    hasChildrenMenu.classList.remove('visible');
                    const parentAnchor = hasChildrenMenu.parentNode.querySelectorAll('a');
                    parentAnchor[0].setAttribute('aria-expanded', "false");
                }
            });
        }
    });
};

// Focus Trap for mobile menu
const mainMenu = document.getElementById('main-menu');
const backdrop = document.getElementById('backdrop');

const hideMenu = function () {
    document.body.classList.remove('noScroll');
    mainMenu.classList.remove('visible');
    backdrop.classList.remove('visible');
    mobileMenuOpen.setAttribute('aria-expanded', 'false');
};

const showMenu = function () {
    document.body.classList.add('noScroll');
    mainMenu.classList.add('visible');
    backdrop.classList.add('visible');
    mobileMenuOpen.setAttribute('aria-expanded', 'true');
};
const menuContainer = document.getElementById('main-menu');

const menuTrap = createFocusTrap('#main-menu', {
    initialFocus: '.main-menu > ul > li:first-of-type > a',
    fallbackFocus: menuContainer,
    clickOutsideDeactivates: true,
    onActivate: function () {
        showMenu();
    },
    onDeactivate: function () {
        hideMenu();
    },
});

// Add focus trap to open menu
const mobileMenuOpen = document.getElementById('mobileMenuOpen');
if (mobileMenuOpen) {
    mobileMenuOpen.addEventListener('click', menuTrap.activate);
}

const menuCloseTriggers = document.querySelectorAll('.menu-close');
for (var i = 0; i < menuCloseTriggers.length; i++) {
    menuCloseTriggers[i].addEventListener('click', menuTrap.deactivate);
};

// Remove role="dialog" and aria-modal="true" on desktop screens
window.addEventListener('load', (event) => {
    const body = document.getElementsByTagName('body');
    body[0].classList.remove('preload');

    if (window.innerWidth > 1072) {
        const mainMenu = document.getElementById('main-menu');
        mainMenu.removeAttribute("aria-modal");
        mainMenu.removeAttribute("aria-label")
        mainMenu.removeAttribute("role");

    }
});

// Focus Trap for search modal
const searchContainer = document.getElementById('searchModal');

const searchTrap = createFocusTrap('#searchModal', {
    initialFocus: '.hs-search-field__input',
    fallbackFocus: searchContainer,
    onActivate: function () {
        searchContainer.classList.add('visible');
    },
    onDeactivate: function () {
        searchContainer.classList.remove('visible');
    },
});

const searchTriggers = document.querySelectorAll('.searchTrigger');
for (var i = 0; i < searchTriggers.length; i++) {
    searchTriggers[i].addEventListener('click', searchTrap.activate);
};

const searchClose = document.getElementById('searchClose');

if (searchClose) {
    searchClose.addEventListener('click', searchTrap.deactivate);
}


// Language Switcher 
// On click show options
const languageBtn = document.querySelector('button.languageSwitcher__current--mobile'); 

if (languageBtn) {
    languageBtn.addEventListener('click', function() {
        console.log('clicked!'); 
        const langMenu = this.nextElementSibling.querySelector('.lang_list_class');

        if (langMenu.classList.contains('active')) {
            langMenu.classList.remove('active');
            this.setAttribute('aria-expanded', "false");
        } else {
            langMenu.classList.add('active');
            this.setAttribute('aria-expanded', "true");
        }
    });
}